<div *ngIf="data.title === success">
  <div id="successModal" class="items-center justify-center">
    <div class="bg-white p-6 rounded-lg">
      <div class="text-center">
        <svg
          class="w-12 h-12 mx-auto text-green-500 mb-4"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M12 8v4l2 2m4-6v4l-4 4m-4-4L6 14" />
        </svg>
        <p class="text-green-600 mt-4">{{ data.message }}</p>
        <button
          mat-button
          (click)="closeModal()"
          class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green active:bg-green-800"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data.title === error">
  <!-- Failure Modal -->
  <div id="failureModal" class="items-center justify-center">
    <div class="bg-white p-6 rounded-lg">
      <div class="text-center">
        <svg
          class="w-12 h-12 mx-auto text-red-500 mb-4"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M15 9l-6 6M9 9l6 6" />
        </svg>
        <p class="text-red-600 mt-4">{{ data.message }}</p>
        <p *ngIf="data.errMessage" class="text-red-600 mt-4">{{ data.errMessage }}</p>
        <button
          mat-dialog-close
          onclick="closeModal()"
          class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:shadow-outline-red active:bg-red-800"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
