import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ResponseType } from '@core/enums/response.enum';
import { MerchantService } from '@core/services/merchants/merchant.service';
import { Observable, Subscription } from 'rxjs';
import { NotificationModalComponent } from 'src/app/layout/components/notification/notification-modal/notification-modal.component';

@Component({
  selector: 'app-web-merchant',
  templateUrl: './web-merchant.page.html',
  styleUrls: ['./web-merchant.page.scss'],
})
export class WebMerchantPageComponent implements OnInit {
  webPaymentForm!: FormGroup;
  merchantWebDetails: any = [];
  slug: string = '';
  merchantInfo$!: Observable<any>;

  constructor(
    private _router: ActivatedRoute,
    private fb: FormBuilder,
    private _merchantService: MerchantService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createWebPaymentForm();
    this._router.paramMap.subscribe((params) => {
      const slug = params.get('slug');
      if (slug) this.getMerchantBySlug(slug);
    });
  }

  createWebPaymentForm() {
    this.webPaymentForm = this.fb.group({
      customerName: ['', Validators.required],
      customerEmail: ['', Validators.required],
      customerMobileNo: ['', Validators.required],
      amount: ['', Validators.required],
      description: ['', Validators.required],
      currency: ['', Validators.required],
      referenceNo: ['', Validators.required],
    });
  }
  get webPaymentFormControl() {
    return this.webPaymentForm.controls;
  }

  getMerchantBySlug(slug: string) {
    this.merchantInfo$ = this._merchantService.getMerchantDetailsBySlug(slug);
  }

  createMerchant() {
    // if(this.webPaymentForm.valid){
    const req = {
      merchantDetails: {
        customerEmail: this.webPaymentForm.value.customerEmail,
        notificationEmail: this.webPaymentForm.value.notificationEmail,
        country: this.webPaymentForm.value.country,
        terminal: this.webPaymentForm.value.terminal,
      },
      settlementDetails: {
        frequency: this.webPaymentForm.value.settlementFrequency,
        surchargeOn: this.webPaymentForm.value.surcharge,
        parentBank: this.webPaymentForm.value.partnerBank,
        settlementAcct: this.webPaymentForm.value.settlementAccount,
        taxNumber: this.webPaymentForm.value.tinNumber,
      },
      userDetails: {
        firstName: this.webPaymentForm.value.firstName,
        lastName: this.webPaymentForm.value.lastName,
        email: this.webPaymentForm.value.email,
        password: `${this.webPaymentForm.value.firstName}_001`,
      },
      bankDetails: {
        bankId: this.webPaymentForm.value.merchantBank,
        branch: this.webPaymentForm.value.branch,
        accountName: this.webPaymentForm.value.accountName,
        accountNumber: this.webPaymentForm.value.accountNumber,
        accountType: this.webPaymentForm.value.accountType,
      },
    };

    this._merchantService.createMerchant(req).subscribe({
      next: (data) => {
        if (data.status == ResponseType.SUCCESS)
          this.openDialog(ResponseType.SUCCESS, 'Merchant successfully created.');
      },
      error: (err) => {
        // Your additional code here
        this.openDialog(ResponseType.ERROR, `Merchant creation failed. ${err.error?.message}`);
      },
    });
    // }
  }

  clearForm() {
    this.webPaymentForm.reset();
  }

  private openDialog(title: string, message: string): void {
    this.dialog.open(NotificationModalComponent, {
      width: '400px',
      height: '220px',
      data: { title, message },
    });
  }

  closeModal(title: string, message: string): void {
    this.dialog.closeAll();
  }
}
