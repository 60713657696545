<div class="login-page p-8">
  <div class="flex justify-start w-full pt-8 pb-8">
    <img src="/assets/logo/logo-main2.svg" class="bg-white rounded-xl" alt="logo" />
  </div>

  <div class="card">
    <h1 class="card-header">Login</h1>
    <h4 class="text-red-300">{{ errMessage }}</h4>
    <div class="card-body">
      <form (ngSubmit)="processLogin()" [formGroup]="$LoginForm">
        <div class="form-group">
          <label class="text-white" for="email">email:</label>
          <input type="text" id="email" formControlName="email" name="email" required />
          <small
            *ngIf="$LoginForm.controls['email'].dirty && $LoginForm.controls['email'].invalid"
            class="invalid-feedback"
          >
            Please enter valid email.
          </small>
        </div>
        <div class="form-group">
          <label class="text-white" for="password">Password:</label>
          <input [formControlName]="'password'" type="password" id="password" name="password" required />
          <small
            *ngIf="$LoginForm.controls['password'].dirty && $LoginForm.controls['password'].invalid"
            class="invalid-feedback"
          >
            Please enter password.
          </small>
        </div>
        <div class="flex justify-between w-full">
          <button class="btn btn-secondary" type="button">Forgot Password</button>
          <!-- <button class="btn btn-secondary" type="submit">Forgot Password</button> -->
          <button class="btn btn-primary" type="submit" [disabled]="isLoading">Login</button>
        </div>

        <!-- <p>Dont have account, <a routerLink="/register">Register</a></p> -->
      </form>
    </div>
  </div>
</div>
