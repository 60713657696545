export function returnLogoForProcessor(processor: string) {
  switch (processor.toLowerCase()) {
    case 'mtn':
      return 'assets/icons/heroicons/outline/mtn-new-logo.svg';
    case 'vodafone':
      return 'assets/icons/heroicons/outline/vodafone.svg';
    case 'airtel':
      return 'assets/icons/heroicons/outline/airtel.svg';
    case 'tigo':
      return 'assets/icons/heroicons/outline/airtel.svg';
    default:
      return 'assets/icons/heroicons/outline/lock-closed.svg';
  }
}
