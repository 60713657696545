import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { GetUserTypeRoute } from '@core/utils/custom-user-type-routes';
import { GetUserType } from '@core/utils/local-storage-data';
import { markInvalidFormControls } from '@core/utils';
import { UserType } from '@core/constants/users.constant';
//import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LoginPage implements OnInit, OnDestroy {
  $LoginForm!: UntypedFormGroup;
  isLoading = false;
  showSpinner = false;
  subscriptions: Subscription[] = [];
  public userType: string = 'administrator';
  errMessage: string = '';
  partnerName: string | undefined;

  constructor(
    private _router: ActivatedRoute,
    private _route: Router,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private el: ElementRef // private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this._authService.authenticated) {
      this._route.navigate([GetUserTypeRoute(GetUserType())]).then();
    } else {
      this._router.paramMap.subscribe((params) => {
        // this.userType = params.get('type');
        const finaluserType: string = params.get('type') ?? this.userType;
        // const finaluserType: string = this.userType === null ? 'administrator' : this.userType;
        this.partnerName = params.get('partnerName') ?? undefined;
        this.initForm(finaluserType);
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initForm(userType: string) {
    this.$LoginForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required]),
        userType: new UntypedFormControl(userType, []),
      },
      {
        updateOn: 'submit',
      }
    );
  }

  processLogin() {
    this.isLoading = true;
    if (this.$LoginForm?.valid) {
      this.$LoginForm.disable();
      if (this.partnerName) {
        this.subscriptions.push(
          this._authService.partnerLogin(this.$LoginForm.value, this.partnerName).subscribe({
            next: (data) => {
              this.navigateUser(data.user_type);
            },
            error: (err) => {
              // Your additional code here
              console.log('Login Failed', err);
              this.errMessage = err.error?.message;
              this.isLoading = false;
              this.$LoginForm.enable();
            },
          })
        );
      } else {
        this.subscriptions.push(
          this._authService.login(this.$LoginForm.value).subscribe({
            next: (data) => {
              //debugger
              // Your additional code here
              //this._notificationService.showSuccess('Login Success')
              this.navigateUser(data.user_type);

              // setTimeout(() => {
              //   this.navigateUser(data.user_type);
              // }, 2000);
            },
            error: (err) => {
              // Your additional code here
              console.log('Login Failed', err);
              this.errMessage = err.error?.message;
              this.isLoading = false;
              this.$LoginForm.enable();
            },
          })
        );
      }
    } else {
      markInvalidFormControls(this.$LoginForm, this.el);
      // Your additional code here
      console.log('Login Failed, Please enter required fields');
      this.isLoading = false;
      this.$LoginForm.enable();
    }
  }

  // ---------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------- Private Functions -------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------

  /**
   * This function navigates to the route of current user type
   * @param userType
   * @private
   */
  private navigateUser(userType: UserType): void {
    const userRoute = GetUserTypeRoute(userType);
    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || userRoute || '/';
    if (userRoute) {
      // window.location.href = redirectURL;
      this._route.navigate([redirectURL]).then();
    } else {
      // Your additional code here
      console.log('You are not authorized to access this page');
      this.isLoading = false;
      this.$LoginForm.enable();
    }
  }
}
